<template>
  <div
    class="radio-input"
    @click="model = radioValue">
    <input
      :id="id"
      v-model="model"
      :value="radioValue"
      type="radio">

    <div class="radio-input__radio-control" />

    <label
      :for="id"
      class="radio-input__label">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    radioValue: {
      type: [
        String,
        Boolean
      ],
      required: true
    },
    value: {
      type: [
        String,
        Boolean
      ],
      default: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-input {
  display: flex;
  align-items: center;

  &__label {
    font-family: "Roboto", Arial, sans-serif;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .radio-input__radio-control {
      background: radial-gradient(currentcolor 50%, rgba(255, 0, 0, 0) 51%);
    }
  }

  &__radio-control {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid currentColor;
    margin-right: 10px;
  }
}
</style>
